<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      :showSave="!readonly"
      :showCancel="!readonly"
      v-on="$listeners"
      no-history
      :show-delete="true"
      :delete-document="deleteProvider"
    />
    <document-in-tab>
      <cot-form
        v-model="isFormValide"
        ref="form"
        :disabled="readonly"
      >
        <identification
          class="mb-3"
          v-model="computedIdentification"
          :is-new="documentIsNew"
          :is-dirty="!tab.isPristine"
          @refresh="refreshCache"
        />
      </cot-form>
    </document-in-tab>
  </div>
</template>

<script>
import authenticationController from '@/authenticationController'
import DocEdit from '@/mixins/document-editor'

export default {
  mixins: [DocEdit],
  components: {
    CotForm: () => import('@/components/cot-form'),
    Identification: () => import('./identification'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar')
  },
  computed: {
    computedIdentification: {
      get () {
        return {
          isActive: this.document?.isActive,
          name: this.document?.name,
          value: this.document?.value
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          isActive: v?.isActive,
          name: v?.name,
          value: v?.value
        })
      }
    },
    cacheType () {
      return DocEdit.CacheType.ProviderDetail
    },
    id () {
      return Number(this.tab.documentId) || 0
    },
    isFormValide: {
      get () {
        return this.dataForm
      },
      set (v) {
        this.dataForm = v
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    }
  },
  data () {
    return {
      dataForm: false,
      readonly: false
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (!this.document?.id) { return }

      this.$store.commit('setModule', {
        name: this.document?.name,
        icon: 'i.Providers'
      })

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    async save () {
      const apiUrl = '/core/providers'

      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)

        this.$nextTick(() => {
          this.$router.push('/providers/' + response.data?.id)
        })

        authenticationController.fetchData().catch(() => { })

        return response
      } else {
        const response = await this.$http().put(`${apiUrl}/${this.id}`, this.document)

        authenticationController.fetchData().catch(() => { })

        return response
      }
    },
    async deleteProvider () {
      const docType = 'providers'

      this.$http().delete(`/core/${docType}/${this.document.id}`)
        .then(async () => {
          authenticationController.fetchData().catch(() => { })
          this.$router.replace('/')
        })
        .catch(e => {
          this.$store.dispatch(
            'showErrorSnackbar',
            e.response?.data?.message
          )
        })
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped></style>
